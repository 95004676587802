import AntForm, { AntSchema, useAntForm } from '@9troisquarts/ant-form';
import { Card, Col, Row} from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { UserType } from '../../../types';
import { castAttributesFromDefinition, ModelDefinitionType } from '@9troisquarts/wand'
import axiosClient from '../../../../utils/axiosClient';

type SignUpProps = {
  user: UserType;
  errors: any;
};

const userDefinition: ModelDefinitionType = {
  firstName: 'String',
  lastName: 'String',
  password: 'Password',
  passwordConfirmation: 'Password',
  email: 'String',
};

const SignUp: React.FC<SignUpProps> = props => {
  const {
    user,
  } = props;
  const intl = useIntl();
  const { object, onChange} = useAntForm(user || {});
  const [errors, setErrors] = useState<any | undefined>(undefined);

  const queryParameters = new URLSearchParams(window.location.search)
  const invitation_token = queryParameters.get("invitation_token")

  const createUser = (admin) => axiosClient.put('/admins/invitation', {
    admin: { ...admin, invitation_token: invitation_token },
  }).then(() => ( window.location.replace('/')) )
    .catch((error) => { 
    setErrors(error.errors);
  });

  const onSubmit = () => {
    createUser(castAttributesFromDefinition(userDefinition, object));
  };

  const signUpSchema: AntSchema = [
    // [
    //   {
    //     name: 'firstName',
    //     label: intl.formatMessage({ id: 'words.first_name' }),
    //     input: {
    //       type: 'string',
    //     },
    //     colProps: {
    //       xs: 24,
    //       md: 12,
    //     },
    //   },
    //   {
    //     name: 'lastName',
    //     label: intl.formatMessage({ id: 'words.last_name' }),
    //     input: {
    //       type: 'string',
    //     },
    //     colProps: {
    //       xs: 24,
    //       md: 12,
    //     },
    //   },
    // ],
    // {
    //   name: 'email',
    //   label: intl.formatMessage({ id: 'words.email' }),
    //   input: {
    //     type: 'string',
    //   },
    //   colProps: {
    //     xs: 24,
    //     md: 12,
    //   },
    // },
    [
      {
        name: 'password',
        label: intl.formatMessage({ id: 'words.password' }),
        input: {
          type: 'password',
        },
        colProps: {
          xs: 24,
          md: 24,
        },
      },
      {
        name: 'passwordConfirmation',
        label: intl.formatMessage({ id: 'words.password_confirmation' }),
        input: {
          type: 'password',
        },
        colProps: {
          xs: 24,
          md: 24,
        },
      },
    ],
    // {
    //   name: 'phone',
    //   label: intl.formatMessage({ id: 'words.phone_number' }),
    //   input: {
    //     type: 'string',
    //   },
    //   colProps: {
    //     xs: 24,
    //     md: 12,
    //   },
    // },
  ]

  return (
    <Row style={{ paddingTop: '10rem' }}>
      <Col xs={18} md={24} offset={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2>Vous avez recu une invitation pour vous inscrire en tant qu'administrateur sur ForcePro</h2>
        <h3>Veuillez configurer le mot de passe de votre compte</h3>
        <Card>
          
          <AntForm
            schema={signUpSchema}
            object={object}
            layout="vertical"
            id="signUpForm"
            // @ts-ignore
            submitText="S'enregistrer"
            onChange={onChange}
            onSubmit={onSubmit}
            errors={errors}
          />
        </Card>
      </Col>
    </Row>
  );
}


export default SignUp;