import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Card, Col, Row, Space } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { AdminType, UserType } from "../../../types";
import { castAttributesFromDefinition } from '@9troisquarts/wand'
import axiosClient from "../../../../utils/axiosClient";
import { useParams } from 'react-router-dom';

type EditPasswordProps = {
  user: UserType;
  resetPasswordToken?: String;
};

const userDefinition = {
  password: 'String',
  passwordConfirmation: 'String',
  resetPasswordToken: 'String',
};

const EditPassword: React.FC<EditPasswordProps> = props => {
  const {
    user,
    resetPasswordToken
  } = props;
  
  const { object, onChange } = useAntForm(user || { 'password': '', 'passwordConfirmation': '' });
  const [errors, setErrors] = useState<any | undefined>(undefined);
  const intl = useIntl();
  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("reset_password_token")
  
  const onEditPasswordSubmit = (admin: AdminType) => axiosClient.put('/admins/password', {
    admin: { 
      ...admin,
      reset_password_token: resetPasswordToken || token,
    },
  }).then((res) => {
    window.location.replace('/')
  }).catch(err => {
    setErrors(err.errors)
  });
  
  const onSubmit = () => onEditPasswordSubmit(castAttributesFromDefinition(userDefinition, object));
  
  
  const editPasswordSchema: AntSchema = [
    // @ts-ignore
    {
      name: 'password',
      label: intl.formatMessage({ id: 'devise.passwords.edit.new_password' }),
      input: {
        type: 'password',
      }
    },
    // @ts-ignore
    {
      name: 'passwordConfirmation',
      label: intl.formatMessage({ id: 'devise.passwords.edit.confirm_new_password' }),
      input: {
        type: 'password',
      }
    }
  ]

  return (
    <Row style={{ padding: '10rem', minHeight: '800px' }}>
      <Col xs={12} md={12} offset={6}>
        <Card>
        <Space direction="vertical">
          <h2>{intl.formatMessage({ id: 'devise.passwords.edit.change_your_password'})}</h2>
          <AntForm
            schema={editPasswordSchema}
            object={object}
            layout="vertical"
            // @ts-ignore
            onChange={onChange}
            onSubmit={onSubmit}
            submitText={intl.formatMessage({ id: 'devise.passwords.edit.change_my_password' })}
            errors={errors}
          />
          </Space>
        </Card>
      </Col>
    </Row>
  )
};

export default EditPassword;