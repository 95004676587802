/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'antd/dist/antd.less';
import '../assets/stylesheets/app.scss'
import '../assets/stylesheets/global.sass';
import '../assets/stylesheets/back_devise_application.scss'
import React from 'react';
import { createRoot } from 'react-dom/client';
import Rails from "@rails/ujs";
import App from '../react/devise/backOffice/App';

// import * as ActiveStorage from "@rails/activestorage";

// @ts-ignore
if(!window._rails_loaded) Rails.start();

const container = document.getElementById('devise_back_office_root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);