import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Button, Card, Col, Row } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { AdminType, UserType } from "../../../types";
import { castAttributesFromDefinition } from '@9troisquarts/wand'
import axiosClient from "../../../../utils/axiosClient";
import { Link } from "react-router-dom";

type ResetPasswordProps = {
  user: UserType;
};

const userDefinition = {
  email: 'String',
};

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const {
    user,
  } = props;

  const resetPassword = (admin: AdminType) => axiosClient.post('/admins/password', {
    admin: { ...admin }
  }).then(() => {
    window.location.replace('/')
  }).catch(err => {
    setErrors(err.errors)
  });

  const onSubmit = () => resetPassword(castAttributesFromDefinition(userDefinition, object));

  const { object, onChange } = useAntForm(user || { 'email': '' });
  const [errors, setErrors] = useState<any | undefined>(undefined);
  const intl = useIntl();
  const ResetPasswordSchema: AntSchema = [
    // @ts-ignore
    {
      name: 'email',
      label: intl.formatMessage({ id: 'devise.mailer.reset_password_instructions.enter_email_address' }),
      input: {
        type: 'string',
      },
      colProps: {
        xs: 24,
        md: 24,
      },
    }
  ]

  return (
    <Row style={{ paddingTop: '10rem' }}>
      <Col xs={18} md={12} offset={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card>
          <AntForm
            schema={ResetPasswordSchema}
            object={object}
            layout="vertical"
            // @ts-ignore
            onChange={onChange}
            onSubmit={onSubmit}
            submitText={intl.formatMessage({ id: 'words.submit' })}
            errors={errors}
          />
        </Card>
        <Link to='/admins/sign_in'>Retour</Link>
      </Col>
    </Row>
  )
};

export default ResetPassword;