import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ResetPassword from './pages/ResetPassword';
import EditPassword from './pages/EditPassword';

type Route = {
  path: string;
  name: string;
  key?: string;
  component?: React.ReactNode;
  hideInMenu?: boolean;
  children?: Route[];
}

const routes: Route[] = [
  {
    path: '/admins/invitation/accept',
    name: 'sign_up',
    component: SignUp,
  },
  {
    path: '/admins/sign_in',
    name: 'sign_in',
    component: SignIn,
  },
  {
    path: '/admins/password/new',
    name: 'reset_password',
    component: ResetPassword,
    hideInMenu: true
  },
  {
    path: '/admins/password/edit',
    name: 'edit_password',
    component: EditPassword,
    hideInMenu: true
  },
];

export default routes;